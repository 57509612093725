import React, { useState, useRef, useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import useMouse from "@react-hook/mouse-position"

import * as containerStyles from "./container.module.css"
import Laptop from "../components/laptop"

const IndexPage = () => {
  const ref = useRef(null);
  const [posX, setXPos] = useState(0);
  const [posY, setYPos] = useState(140);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  useEffect(() => {
    setYPos(mouse.y && mouse.elementHeight ? mouse.y / mouse.elementHeight : 0);
    setXPos(mouse.x && mouse.elementWidth ? mouse.x / mouse.elementWidth : 0);
  }, [mouse]);

  return (
    <Layout>
      <Seo title="Home" />
      <main ref={ref}>
        <div className={containerStyles.main}>
          <div className={containerStyles.brgraphic}>
            <Laptop posY={posY} posX={posX} />
          </div>
          <h1 className={containerStyles.title}>
            Hello, my name is <strong>Alasdair Mackenzie</strong>.
          </h1>
          <p>
            I&apos;m a Software Development Manager at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.theaccessgroup.com/en-gb/"
            >
              The AccessGroup
            </a>{" "}
            within the
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.theaccessgroup.com/en-gb/hospitality"
            >
              {" "}
              Hospitality division
            </a>
            . Find me on{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/alasdair-mackenzie-74211a17"
            >
              LinkedIn
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/alasdairmackenzie"
            >
              GitHub
            </a>
            , or{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/allymackuk"
            >
              Twitter
            </a>
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
